/* eslint-disable no-control-regex */
import moment from "moment";
import { Arrays, Strings, resolveLocale } from "portal-components";
import Cookies from "universal-cookie";
import { languages } from "../i18n/translate";
import { getGA } from "./googleAnalytics";

const cookie = new Cookies();

export const IdentifierRegEx = new RegExp(
    /^(?![0-9_\s])[-A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF]*_?[-A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF]+$/i
);

let nextUniqueId = 0;
function getNextUniqueId() {
    return (nextUniqueId++).toString();
}

function iif(condition: any, valueIfTruthy: any, valueIfFalsy: any = undefined) {
    return condition ? valueIfTruthy : valueIfFalsy;
}

function getUserUiLanguage() {
    return window.localStorage.getItem("lang") || getBrowserLang() || "en_us";
}

function resolveFormattingLanguage() {
    // If UI is en-US then we format date/numbers as suggested by the Browser, this is because
    // en-US is the language used when there is not a specific translation for the user's language.
    // However, if user has selected a specific translation then we assume he is also from that country.
    const uiLanguage = getUserUiLanguage();
    if (uiLanguage === "en_us") {
        return getBrowserLang();
    }

    return getMomentLocale(uiLanguage);
}

function getMomentLocale(langLocale: string) {
    const overrides: Record<string, string> = {
        zh: "zh-CN",
        en_us: "en-US",
        pseudo: "x-pseudo",
    };
    return langLocale in overrides ? overrides[langLocale] : langLocale;
}

function isoDate(date: string | Date | undefined, rawFormat = "") {
    return moment.utc(date).format(rawFormat);
}

function hasDatePassed(date: string | Date | undefined) {
    const diff = moment().diff(date, "second");
    const monthDiff = moment().diff(date, "month");
    if (diff >= 0) {
        return expirationTime.EXPIRED;
    }
    // six month to pass
    if (monthDiff >= -6) {
        return expirationTime.EXPIRING_SOON;
    }
    return expirationTime.VALID;
}

const formValidationLimits = {
    password: {
        min: 8,
        max: 512,
    },
};

function formValidate(rawData = "", type: "password" | "email" | "otp", isRequired = true, trim = true) {
    // do not trim string for password
    const data = type === "password" || typeof rawData === "object" || !trim ? rawData : rawData.trim();

    if (isRequired && !data) {
        return { result: false, errorText: "should not be empty" };
    }

    switch (type) {
        case "password":
            if (data.length < formValidationLimits.password.min) {
                return {
                    result: false,
                    errorText: "is too short",
                };
            }

            if (data.length > formValidationLimits.password.max) {
                return {
                    result: false,
                    errorText: "is too long",
                };
            }
            break;
        case "email":
            if (!Strings.isValidEmail(data)) {
                return {
                    result: false,
                    errorText: "is invalid",
                };
            }
            break;
        case "otp":
            return data.replace(/\s+/g, "");
        default:
            break;
    }

    return { result: data, errorText: false };
}

function loadCookie(key: string) {
    return cookie.get(key);
}

function removeCookie(key: string, options = {}) {
    return cookie.remove(key, { path: "/", ...options });
}

function saveCookie(key: string, value: any, options = {}) {
    options = Object.assign({ secure: true, path: "/" }, options);
    return cookie.set(key, value, options);
}

function getBrowserLang() {
    const rawLang = (window.navigator as any).userLanguage || window.navigator.language;
    const language = rawLang.replace("-", "_").toLowerCase();
    if (language in languages) {
        return language;
    }

    // Translate en-US/en-GB -> en
    const locale = language.split("_")[0];
    if (locale in languages) {
        return locale;
    }

    return null;
}

function pluralize(
    size: number,
    singularString: string | undefined,
    pluralString: string | undefined,
    culture = resolveFormattingLanguage()
) {
    return Strings.isPlural(size, culture) ? pluralString ?? "" : singularString ?? "";
}

// http://stackoverflow.com/a/18116302
// Converts an object of k/v pairs into querystring
function serialize(obj: object, encode = true) {
    return Object.keys(obj)
        .map((k) => {
            if (encode) return k + "=" + encodeURIComponent((obj as any)[k]);
            return k + "=" + (obj as any)[k];
        })
        .join("&");
}

// Flatten data objects
// Code from https://gist.github.com/penguinboy/762197
function flattenObject(object: any, separator = ".") {
    // Funciton for checking if the passed in object is truly and object
    const isValidObject = (value: any) => {
        if (!value) {
            return false;
        }
        const isArray = Array.isArray(value);
        // Find out what kind of object we have
        // Date, undefined, and null are also considered object types
        // We only want to validate against object objects
        // Object.prototype.toString.call() returns “[object type]”, where type is the object type
        const isObject = Object.prototype.toString.call(value) === "[object Object]";
        const hasKeys = !!Object.keys(value).length;
        // Ignore arrays and empty objects
        return !isArray && isObject && hasKeys;
    };
    // Function for flattening an object, recursively calls itself
    const walker = (child: any, path: string[] = []): object => {
        return Object.assign(
            {},
            ...Object.keys(child).map((key) => {
                return isValidObject(child[key])
                    ? walker(child[key], path.concat([key]))
                    : { [path.concat([key]).join(separator)]: child[key] };
            })
        );
    };
    // Start the flattening process
    return Object.assign({}, walker(object));
}

const expirationTime = {
    VALID: 0,
    EXPIRED: 1,
    EXPIRING_SOON: 2,
};

const modalType = {
    CONFIRM: "CONFIRM",
    DIALOG: "DIALOG",
};

const HTTPCodes = {
    NO_RESPONSE: 0,
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,
    OKAY: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    TEMPORARY_REDIRECT: 307,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTH_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    PAYLOAD_TOO_LARGE: 413,
    URI_TOO_LONG: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    RANGE_NOT_SATISFIABLE: 416,
    EXPECTATION_FAILED: 417,
    I_AM_A_TEAPOT: 418,
    UPGRADE_REQUIRED: 426,
    TOO_MANY_REQUESTS: 429,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    HTTP_VERSION_NOT_SUPPORTED: 505,
};

const CertificateServer = {
    BOOTSTRAP: "bootstrap",
    LWM2M: "lwm2m",
};

const AccountStatus = {
    ACTIVE: "ACTIVE",
    EXPIRED: "EXPIRED",
    INACTIVE: "INACTIVE",
    RESET: "RESET",
    RESTRICTED: "RESTRICTED",
    SUSPENDED: "SUSPENDED",
};

const Documentation = {
    accessManagement: "/account-management/index.html",
    accessPolicies: "/account-management/index.html",
    accountSecurity: "/account-management/acct-security.html",
    applicationAccessKey: "/user-account/application-access-keys.html",
    certAndCa: "/provisioning-process/certificates-and-certificate-authorities.html",
    connectingToMbed: "/connecting/device-onboarding.html",
    deviceEvent: "/device-management/device-event-log.html",
    deviceFilter: "/device-management/device-filters.html",
    deviceGroups: "/device-management/creating-and-managing-device-groups.html",
    deviceManagement: "/device-management/current/welcome/index.html",
    deviceEnrollment: "/connecting/device-ownership-first-to-claim-by-enrollment-list.html",
    factoryTool: "/device-management-provision/latest/introduction/index.html",
    firmwareImages: "/updating-firmware/firmware-images.html",
    manageUsers: "/account-management/manage-users.html",
    manifest: "/updating-firmware/firmware-manifests.html",
    mbedCloudUpdate: "/updating-firmware/index.html",
    provisioning: "/provisioning-process/index.html",
    provisioningDevelopment: "/connecting/provisioning-development-devices.html",
    provisioningInfo: "/device-management-provision/latest/introduction/index.html",
    pskLulLink: "/connecting/pelion-device-management-client-lite-security-considerations.html",
    pskUpload: "/connecting/device-management-client-lite.html",
    sda: "/provisioning-process/secure-device-access.html",
    subscribeToResource: "/connecting/resource-change-webapp.html",
    subtenants: "/account-management/aggregator-accounts.html",
    updateCampaigns: "/updating-firmware/update-campaigns.html",
    dashboardCustom: "/user-account/creating-a-custom-dashboard.html",
    teamIdentityLink: "/team-management/identity-providers.html",
    teamProfileLink: "/team-management/profile.html",
    insightSessionLink: "/device-management/monitoring-device-health-insights-sessions.html",
    releaseNotes: "/device-management-provision/latest/fcu-rn/index.html",
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item: unknown): item is object {
    return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects. Does not mutate it's arguments.
 * @param target
 * @param ...sources
 */
function mergeDeep(target: object, ...sources: object[]): object {
    if (!sources.length) return target;
    const source = sources[0];

    const merged: object = Object.entries(source).reduce((acc, [key, value]) => {
        const mergedValue = isObject(value) ? mergeDeep((target as any)[key] || {}, value) : value;

        return { ...acc, [key]: mergedValue };
    }, target);

    return mergeDeep(merged, ...sources.slice(1));
}

/**
 * Convert number to localized string
 * @param number
 * @param style
 * @param language
 * @returns {string}
 */
function numberToLocale(number: number, style: string, language: string | undefined) {
    return new Intl.NumberFormat(resolveLocale(language?.replace(/^x-/, "")), {
        style,
        localeMatcher: "best fit",
    }).format(number);
}

/**
 * Get a nested object field, return specified value if field is not found
 * @param object
 * @param ...path
 * @param ifNull
 * @param validateRoot
 * @returns {value}
 */
function getNestedField(object: any = {}, path: string[] = [], ifNull: any = null, validateRoot = true) {
    // The recursive traversing function
    // First check if we are at the end of the path, if so return the object
    // If path exists, then check if the next element is in the object
    // If not, then our field doesn't exists
    // Otherwise, traverse into the next path
    const traverse = (object: any, path: string[], ifNull: any): any => {
        return !Array.isArray(path) || path.length === 0
            ? object
            : !isObject(object) || !Object.hasOwnProperty.call(object, path[0])
            ? ifNull
            : traverse((object as any)[path[0]], path.slice(1), ifNull);
    };

    // If validating the root, first check if the original object is a valid object,
    // if not, just start the process
    return !validateRoot || isObject(object) ? traverse(object, path, ifNull) : ifNull;
}

/**
 * Split a string by space, comma, and semicolons
 * @param string
 * @returns [string]
 */
function split(string = "", separator = /[ ,;]+/) {
    if (typeof string !== "string") {
        return [];
    }
    // Trim the string, split, trim the sub-strings and only return sub-strings with length
    return string
        .trim()
        .split(separator)
        .map((string) => string.trim())
        .filter((string) => string.length > 0);
}

/**
 * Validate a string only has alphanumeric characters
 * Optional flag for requiring starting with a letter
 * @param string
 * @param bool
 * @returns bool
 */
function validateAlphanumeric(string = "", alphaStart = false) {
    if (typeof string !== "string") {
        return false;
    }
    // if requiring string to start with a letter or with any alphanumeric character
    const regex = alphaStart ? /^[a-zA-Z](?:[a-zA-Z0-9])*$/ : /^(?:[a-zA-Z0-9])*$/;
    // Test the string
    return regex.exec(string) !== null;
}

function debounce(fn: () => void, waitMillis: number) {
    let timeoutId: number | null = null;

    const debounced = function debounced() {
        if (!timeoutId) {
            timeoutId = window.setTimeout(() => {
                timeoutId = null;
                fn();
            }, waitMillis);
        }
    };

    debounced.cancel = function () {
        if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
        }
    };

    return debounced;
}

function mergeBuiltInListWithConfiguration(builtInList: Record<string, any>, configurationList: Record<string, any>) {
    /* eslint-disable-next-line eqeqeq */
    if (configurationList == null) {
        configurationList = {};
    }

    const builtInNames = Object.keys(builtInList);
    const configNames = Object.keys(configurationList);

    // Merge properties from config to built-in list
    const result = builtInNames.map((name) => {
        const fromBuiltIn = builtInList[name];
        const fromConfig = configurationList[name];

        return { ...fromBuiltIn, ...fromConfig };
    });

    // Search for items newly defined in configuration and add them to the list
    Arrays.except(configNames, builtInNames).forEach((name) => result.push(configurationList[name]));

    return result;
}

function evalBoolean(value: any, functionArgs?: unknown): boolean {
    if (!value) {
        return false;
    }

    return typeof value === "function" ? evalBoolean(value(functionArgs)) : true;
}

function shallowEqual(lhs: object, rhs: object, ignoreMissingProperties = true) {
    function compare(a: any, b: any) {
        return Object.keys(a).every((property) => {
            if (ignoreMissingProperties && !Object.prototype.hasOwnProperty.call(b, property)) {
                return true;
            }

            const valueA = a[property];
            const valueB = b[property];

            // To test for sub-properties is tricky, this is a SIMPLE
            // shallow equality comparer. We also deliberately ignore functions.
            // We assume that properties have the same type in both objects.
            const propertyType = typeof valueA;
            if (propertyType === "object" || propertyType === "function") {
                return true;
            }

            return valueA === valueB;
        });
    }

    const isRhsNull = rhs === null;
    const isRhsUndefined = rhs === undefined;

    if (lhs === null) {
        return isRhsNull;
    }

    if (lhs === undefined) {
        return isRhsUndefined;
    }

    if (isRhsNull || isRhsUndefined) {
        return false;
    }

    return compare(lhs, rhs) && (ignoreMissingProperties || compare(rhs, lhs));
}

function formatErrorResponse(response: any, formatter?: ((value: string) => string) | null, url?: string) {
    const formatText = (text: string) => (formatter ? formatter(text) : text);

    if (!response) {
        return formatText("");
    }

    if (response.body) {
        return formatText(
            isObject(response.body.message)
                ? response.body.message.message
                : response.body.message || response.body.error
        );
    }

    url = url ? ` - '${url}'` : "";

    return formatText(`${response.statusText} (${response.status})${url}`);
}

const concatData = (currentData: any[], appendData: any[]) => {
    currentData = currentData.map((item) => {
        const newItem = appendData.find((element) => {
            return item.id === element.id;
        });
        return newItem ? newItem : item;
    });
    let data = currentData.concat(
        appendData.filter(function (item) {
            return !currentData.find((element) => {
                return item.id === element.id;
            });
        })
    );
    data = data.map((item, index) => {
        return Object.assign(item, { index });
    });
    return data;
};

const reloadPage = () => window.location.reload(true);

function updateAtIndex<T>(index: number, update: (value: T) => T, array: T[]) {
    return index < array.length && index >= 0
        ? [...array.slice(0, index), update(array[index]), ...array.slice(index + 1)]
        : array;
}

/**
 * @param isEqual
 * @returns {function(*): Function}
 */
const memoize = (isEqual: (a: Record<string, unknown>, b: Record<string, unknown>) => boolean) => (
    fn: (arg: unknown) => unknown
) => {
    let called = false;
    let lastArg: any;
    let lastResult: any;

    return (arg: any) => {
        if (called && isEqual(lastArg, arg)) {
            return lastResult;
        }

        lastResult = fn(arg);
        lastArg = arg;
        called = true;
        return lastResult;
    };
};

const memoizeRef = memoize((a: object, b: object) => a === b);
const memoizeShallow = memoize((a: object, b: object) => shallowEqual(a, b, false));

const memoizeKeys = (keys: string[]) =>
    memoize((a: Record<string, unknown>, b: Record<string, unknown>) => {
        if (a === b) return true;
        if (!a || !b) return false;
        return keys.every((key: any) => a[key] === b[key]);
    });

interface ViewComponentProps {
    titles?: any;
    title?: string;
    config?: any;
    strings?: any;
}

const setTitle = (props: ViewComponentProps) => {
    const { titles = {}, title, strings = {}, config } = props;
    if (!title) {
        return;
    }
    const seperator = " - ";
    const isOnPrem = config ? config.onPremises : false;
    const titleName = isOnPrem ? "mainTitleOnPrem" : "mainTitle";
    const pageTitle = titles[title] || strings[title] || "";
    const mainTitle = titles[titleName] || strings[titleName] || document.title.split(seperator).slice(-1)[0];
    document.title = [pageTitle, mainTitle].join(seperator);
};

const sendEvent = (eventName: string, action: string) => {
    getGA().event({
        category: eventName,
        action: action,
    });
};

const getSelectedRows = (data: any[] | undefined) => {
    return data?.filter((row) => !!row.selected) ?? [];
};

const clearSelectedRows = (data: any[] | undefined) => {
    return data?.map((row) => {
        row.selected = false;
        return row;
    });
};

const getFeatureToggle = (feature = "", props: ViewComponentProps) => {
    // If not explicitly allowed, then access is denied. Required for features set in config file.
    return !!feature && getNestedField(props, ["config", "featureToggle", feature], false);
};

const getFeatureToggleFromConfig = (feature = "", config: object) => {
    // If not explicitly allowed, then access is denied. Required for features set in config file.
    return !!feature && getNestedField(config, ["featureToggle", feature], false);
};

const getUserDisplayName = ({ email = "", fullName = "", full_name = "" } = {}) => {
    const hasFullName = fullName || full_name;
    return hasFullName ? `${hasFullName} <${email}>` : email;
};

function convertSearchToQuery(search: string) {
    return Strings.convertSearchStringToObject(search);
}

function resolveUrl(address: string | null | undefined, templatUrl?: object | null) {
    if (!address) {
        return undefined;
    }

    const current: any = templatUrl ?? new URL(window.location.toString());
    return address.replace(/\$\{([a-z]+)\}/gi, (_, p1) => current[p1]);
}

function isValidHexUUID(hex: string) {
    const regex = /^[a-fA-F0-9]{32}$/;
    return regex.test(hex);
}

function downloadAsXmlFileFromData(data: string, fileName: string) {
    const blob = new Blob([data], { type: "text/xml" });
    const fileUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = fileUrl;
    a.download = fileName;
    a.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(fileUrl);
        document.body.removeChild(a);
    }, 0);
}

export {
    AccountStatus,
    CertificateServer,
    concatData,
    debounce,
    Documentation,
    evalBoolean,
    expirationTime,
    flattenObject,
    formatErrorResponse,
    formValidate,
    formValidationLimits,
    getBrowserLang,
    getMomentLocale,
    getNestedField,
    getUserUiLanguage,
    hasDatePassed,
    HTTPCodes,
    iif,
    isObject,
    isoDate,
    pluralize,
    loadCookie,
    memoize,
    memoizeKeys,
    memoizeRef,
    memoizeShallow,
    mergeBuiltInListWithConfiguration,
    mergeDeep,
    modalType,
    getNextUniqueId,
    numberToLocale,
    reloadPage,
    removeCookie,
    resolveFormattingLanguage,
    saveCookie,
    serialize,
    shallowEqual,
    split,
    updateAtIndex,
    validateAlphanumeric,
    setTitle,
    sendEvent,
    getSelectedRows,
    clearSelectedRows,
    getFeatureToggle,
    getFeatureToggleFromConfig,
    getUserDisplayName,
    resolveUrl,
    convertSearchToQuery,
    isValidHexUUID,
    downloadAsXmlFileFromData,
};
